<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Benutzer</h4>
                            <p>Liste aller Benutzer</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadUsers">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('users.create')">
                                Create User
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="users" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('users.edit')"><i class="fas fa-edit"></i></b-button>
                                        <b-button size="sm" @click="deleteUser(row.item.id)" variant="danger" v-if="$auth.check('users.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <b-modal id="userModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname</label>
                                <div class="col-sm-9">
                                    <input v-model="form.first_name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('first_name')}" tabindex="1"/>
                                    <has-error :form="form" field="first_name"></has-error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Username</label>
                                <div class="col-sm-9">
                                    <input v-model="form.username" type="text" name="username" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('username')}" tabindex="3"/>
                                    <has-error :form="form" field="username"></has-error>
                                </div>
                            </div>

                            <div class="form-group row" v-if="showPassword === true">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Passwort</label>
                                <div class="col-sm-9">
                                    <input v-model="form.password" type="password" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('password')}" tabindex="5"/>
                                    <has-error :form="form" field="password"></has-error>
                                </div>
                            </div>
                            

                            <div class="form-group row" v-if="editMode == true">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Passwort ändern?</label>
                                <div class="col-sm-9">
                                    <select v-model="changePassword" class="form-control form-control-sm">
                                        <option v-bind:value=true>Ja</option>
                                        <option v-bind:value=false>Nein</option>
                                    </select>
                                </div>
                            </div>


                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Nachname</label>
                                <div class="col-sm-9">
                                    <input v-model="form.last_name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('last_name')}" tabindex="2"/>
                                    <has-error :form="form" field="last_name"></has-error>
                                </div>
                            </div>


                            <div class="form-group row" v-if="showPassword === true">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Passwort W.</label>
                                <div class="col-sm-9">
                                    <input
                                        v-model="form.password_confirmation"
                                        type="password"
                                        class="form-control form-control-sm"
                                        :class="{
                                            'is-invalid': form.errors.has('password_confirmation'),
                                        }" 
                                        tabindex="6"
                                    />
                                    <has-error :form="form" field="password_confirmation"></has-error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Benutzerrolle</label>
                                <div class="col-sm-9">
                                    <select v-model="form.roles" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('roles')}" multiple tabindex="8">
                                        <option v-for="role in roles" :key="role.id" v-bind:value="role.id">{{role.name}}</option>
                                    </select>
                                    <has-error :form="form" field="roles"></has-error>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Filialen</label>
                                <div class="col-sm-9">
                                    <select v-model="form.stores" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('stores')}" multiple tabindex="10">
                                        <option v-for="store in stores" :key="store.id" v-bind:value="store.id">{{store.name}}</option>
                                    </select>
                                    <has-error :form="form" field="stores"></has-error>
                                </div>
                            </div>



                        </div>
                    </div>
                </form>
            </b-modal>
        </div>
    </b-container>
</template>

<script>
//import $ from "jquery";

export default {
    name: 'Users',
    title: 'Benutzer',
    data() {
        return {
            form: new window.Form({
                id: "",
                first_name: "",
                last_name: "",
                username: "",
                password: "",
                password_confirmation: "",
                roles: [],
                stores: [],
            }),
            modalTitle: '',
            editMode: false,
            roles: null,
            stores: null,
            edit: null,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "first_name", label: "Vorname", sortable: true},
                {key: "last_name", label: "Nachname", sortable: true},
                {key: "username", label: "Benutzername", sortable: false},
                {key: "actions", label: "Actions"},
            ],
            users: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            changePassword: false,
        };
    },

    methods: {

        handleOk(bvModalEvt, user) {
            bvModalEvt.preventDefault()
            this.handleSubmit(user)
        },

        handleSubmit(user) {
            if(this.editMode == true)
            {
                //Edit User
                this.editUser(user);
            }
            else
            {
                //Create User
                this.createUser();
            }
        },

        createModal() {
            // eslint-disable-next-line no-undef
            //$("#modal-default").modal("show");
            this.editMode = false;
            this.modalTitle = "Neuen Benutzer anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("userModal");
        },

        editModal(User) {
            this.changePassword = false;
            this.modalTitle = "Benutzer editieren";
            this.editMode = true;
            this.form.reset();
            this.form.fill(User);
            this.$bvModal.show("userModal");
        },

        deleteUser(id) {
            this.$swal({
                    title: "Möchtest du den Benutzer wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/users/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Benutzer erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadUsers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },
        editUser(user) {
            this.form
                .put("/users/"+user)
                .then(() => {
                    this.$bvModal.hide("userModal");
                    this.$swal({
                        icon: "success",
                        title: "Benutzer gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadUsers();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        createUser() {
            this.form
                .post("/users")
                .then(() => {
                    this.$bvModal.hide("userModal");
                    this.$swal({
                        icon: "success",
                        title: "Benutzer gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadUsers();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        async loadUsers() {
            await this.axios
                .get("/users")
                .then((response) => {
                    this.users = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        onEdit(index) {
            this.edit = this.edit !== index ? index : null;
        },

        loadRoles() {
            return this.axios.get('/roles')
                .then((response) => {
                    this.roles = response.data.data;
                });
        },

        loadStores() {
            return this.axios.get('/stores')
                .then((response) => {
                    this.stores = response.data.data;
                });
        },
    },

    computed: {
        tableData() {
            return this.users || [];
        },

        rows() {
            return this.users.length;
        },

        showPassword() {
            if(this.editMode == true && this.changePassword == true)
            {
                return true;
            }

            else if(this.editMode == false && this.changePassword == true)
            {
                return true;
            }

            else if(this.editMode === false && this.changePassword === false)
            {
                return true;
            }
            else{
            return false;
            }
        },
    },

    created() {
        this.loadUsers();
        this.loadRoles();
        this.loadStores();
    },
};
</script>
